.age-group-infants {
    background-color: #662c90; 
    color: white;
  }
  
  .age-group-children {
    background-color: #2c74be; 
    color: white;
  }
  
  .age-group-boys {
    background-color: #3cadf1; 
    color: white;
  }

  .age-group-girls {
    background-color: #29a89f; 
    color: white;
  }
  
  .age-group-men {
    background-color: #3ab549; 
  }

  .age-group-women {
    background-color: #8cc63c; 
  }
  
  .age-group-elderly {
    background-color: #fcef0a; 
  }
  .MuiDataGrid-row {
    height: 30px; 
  }
  

  
  