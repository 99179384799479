/* Default style for the SVG component */
.readial-component {
    width: 100%;
    height: auto;
  }
  
  /* Media query for screens with width greater than or equal to 768px */
  @media only screen and (min-width: 768px) {
    .readial-component {
        width: 100%;
        height: auto;
       
    }
  }
  
  /* Media query for screens with width less than 768px */
  @media only screen and (max-width: 767px) {
    .readial-component {
      width:100%;
      height: auto;
    
    }
  }