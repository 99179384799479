.rectangle {
  width: 100%;
  height: 100%;
  background-color: #ffffff;
  clip-path: polygon(0 0, 100% 0, 100% 100%, 90% 100%, 100% 50%, 90% 0);
}
.rectangle_sub {
  width: 100%;
  height: 100%;
  /* background-color: #ffffff; */
  /* clip-path: polygon(24.06% 0.54%, 75% 0%, 69.5% 50%, 75% 100%, 24.06% 99.45%); */
 clip-path: polygon(0% 0%, 71.04% 0%, 68.32% 50%, 71.04% 100%, 0.06% 100%);
background-color: #000000
}
