.root {
	--hue: 223;
	--bg: hsl(var(--hue), 90%, 95%);
	--fg: hsl(var(--hue), 90%, 5%);
	--trans-dur: 0.3s;
	font-size: 5px;
	display: flex;
  justify-content:center ;
  align-items: center;
  height: 60vh;
  }

main {
	padding: 1.5em 0;
}
.ip {
	width: 16em;
	height: 8em;
}
.ip__track {
	stroke: hsl(var(--hue),90%,90%);
	transition: stroke var(--trans-dur);
}
.ip__worm1,
.ip__worm2 {
	animation: worm1 2s linear infinite;
}
.ip__worm2 {
	animation-name: worm2;
}

/* Dark theme */
@media (prefers-color-scheme: dark) {
	.root {
		--bg: hsl(var(--hue),90%,5%);
		--fg: hsl(var(--hue),90%,95%);
	}
	.ip__track {
		stroke: hsl(var(--hue),90%,15%);
	}
}

/* Animation */
@keyframes worm1 {
	from {
		stroke-dashoffset: 0;
	}
	50% {
		animation-timing-function: steps(1);
		stroke-dashoffset: -358;
	}
	50.01% {
		animation-timing-function: linear;
		stroke-dashoffset: 358;
	}
	to {
		stroke-dashoffset: 0;
	}
}
@keyframes worm2 {
	from {
		stroke-dashoffset: 358;
	}
	50% {
		stroke-dashoffset: 0;
	}
	to {
		stroke-dashoffset: -358;
	}
}
.text-animation {
	position: relative;
	display: flex;
	flex-direction: column;
	align-items: center;
	width: 200px;
  }
  
  .text-item {
	position: absolute;
	opacity: 0;
	font-size: 16px;
    transition: opacity 0.5s ease-in-out;
	animation: fade-in-out 4s linear infinite;
  }
  
  .text-item.animate {
	opacity: 1;
  }
  
  @keyframes wave-animation {
	0% {
	  transform: translateY(20px);
	}
	25% {
	  transform: translateY(0);
	}
	75% {
	  transform: translateY(0);
	}
	100% {
	  transform: translateY(-20px);
	}
  }
  