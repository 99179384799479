
  @media print {
    .hide-in-print {
      display: none;
    }
    .print-title {
      display: block;
      position: fixed;
      top: 0;
      left: 0;
      width: 100%;
      background-color: rgb(14, 13, 13);
      padding: 10px;
      box-sizing: border-box;
    }
    .print-footer {
      display: block;
      position: fixed;
      bottom: 0;
      left: 0;
      width: 100%;
      background-color: rgb(14, 13, 13);
      padding: 10px;
      box-sizing: border-box;
    }
  }
  
  @media screen {
    .print-title,
    .print-footer {
      display: none;
    }
  }

.MuiDataGrid-columnHeader,
  .MuiDataGrid-columnHeaderTitleContainer,
  .MuiDataGrid-columnHeaderTitle {
    background-color: #f4cf65;
  }
  .custom-form-control {
    border-radius: 20px; /* Adjust the border radius for rounded corners */
  }
  
  .custom-form-control input {
    border: 2px solid hsla(0, 100%, 50%, 0.425); /* Set the border style and color */
    padding: 10px; /* Add padding to the input field */
    outline: none; /* Remove the default outline on focus */
  }
  
  /* Add custom styles for the search icon (optional) */
  .custom-form-control .MuiSelect-icon {
    top: 20px; /* Adjust the vertical position of the search icon */
    right: 10px; /* Adjust the horizontal position of the search icon */
  }
  